//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";

//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBCardBody, MDBCol, MDBContainer, MDBRow, MDBCard } from "mdbreact";

//> CSS
import "./index.scss";

//> Images
import IMGlogo from "../../../assets/images/logo_white_sm.png";
//#endregion

//#region > Components
class HomePage extends React.Component {
  render() {
    return (
      <MDBContainer id="home" className="text-center text-white py-5 my-5">
        <img src={IMGlogo} alt="SithCult logo" className="img-fluid" />
        <p className="lead font-weight-bold mt-3 mb-0">1997 - 2021</p>
        <p className="text-muted basic hand">The end</p>
        <p className="lead">
          In light of years of unwavering dedication, substantial investments,
          and an enduring commitment to our shared ideals and objectives, it is
          with a heavy heart that we announce the conclusion of the SithCult
          era.
        </p>
        <p>
          The executive leadership formally declares the dissolution of
          SithCult, encompassing all affiliated sub-organizations and
          departments, effective immediately.
        </p>
        <MDBRow className="flex-center my-5">
          <MDBCol lg="6">
            <MDBCard>
              <MDBCardBody className="text-dark">
                <p>
                  <i>
                    "It has become abundantly clear to us, the leadership, that
                    despite our most earnest efforts, we are unable to restore
                    SithCult to its former glory that it enjoyed prior to the
                    year 2015. We endeavored to recreate the organizational
                    structure and the unique ethos that once defined us, but we
                    must acknowledge that we have fallen short of this
                    monumental task.
                  </i>
                </p>
                <p>
                  <i>
                    Consequently, after more than two decades—24 years to be
                    precise—of unwavering commitment and service, we have made
                    the difficult decision to formally dissolve SithCult in its
                    entirety. This includes not only specialized organizations
                    such as the Imperial Youth but also all functional
                    departments and task groups, irrespective of their global
                    locations. The command structure that once governed SithCult
                    is hereby disbanded.
                  </i>
                </p>
                <p>
                  <i>
                    It is our sincerest hope that our journey has served as an
                    inspiration for you to carve out your own destiny—a destiny
                    not dictated by a society that fails to uphold the values of
                    Sith-Imperial tradition.
                  </i>
                </p>
                <br />
                <p>
                  <i>
                    <strong>
                      May the Sith Flame eternally burn in honor of those we
                      have lost along the way.
                    </strong>
                    "
                  </i>
                </p>
                <p>- Darth Venes</p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <p className="mt-5">
          May the Dark Side guide your path. And remember...
        </p>
        <p className="basic hand text-muted">
          Peace is a lie, there is only passion.
          <br />
          Through passion, I gain strength.
          <br />
          Through strength, I gain power.
          <br />
          Through power, I gain victory.
          <br />
          Through victory, my chains are broken.
          <br />
          The Force shall free me.
        </p>
      </MDBContainer>
    );
  }
}
//#endregion

//#region > Exports
export default HomePage;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019-2020 Werbeagentur Christian Aichner
 */
